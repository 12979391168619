<template>
  <main>
    <sqr-progress />
  </main>
</template>

<script>
import { mapActions } from 'vuex';
import SqrProgress from '@/sqrd/SqrProgress';

export default {
  name: 'SignOut',
  components: { SqrProgress },
  async mounted() {
    try {
      await this.signOut();
      this.$router.replace({ name: 'home' });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  methods: {
    ...mapActions('auth', ['signOut']),
  },
};
</script>
